// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceWorkOrdersFullLoad: false,
        maintenanceWorkOrdersLiteLoad: false,
        maintenanceWorkOrders: [],
        maintenanceWorkOrdersStatuses: {},
        maintenanceWorkOrdersPriorities: {},
        maintenanceWorkOrdersDiscounts: {},
        maintenanceWorkOrdersTaxs: {},
        itemConvertToWorkOrder: null,
    },
    actions: {
        fetchMaintenanceWorkOrders/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrders', time: Date.now() / 1000})

                const params = getApiProps('maintenance_work_order', args)
                this.$api.maintenanceworkorders.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrders', inprogress: false})
                    });
            })
        },
        fetchMaintenanceWorkOrdersAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceWorkOrdersLiteLoad) {
                    return resolve(getters.maintenanceWorkOrders.length)
                }
                this.$api.init.getMaintenanceWorkOrders()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_work_orders) {

                            commit('setMaintenanceWorkOrders', response.data.maintenance_work_orders)
                            commit('setMaintenanceWorkOrdersLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceWorkOrdersAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceWorkOrdersAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceWorkOrdersLiteLoad) {
                    return resolve(getters.maintenanceWorkOrders.length)
                }
                dispatch('fetchMaintenanceWorkOrders', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceWorkOrders', data)
                        commit('setMaintenanceWorkOrdersLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceWorkOrdersAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceWorkOrdersAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.maintenanceWorkOrders.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.maintenanceWorkOrders.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceWorkOrders', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceWorkOrders', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceWorkOrders', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceWorkOrdersFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersChanged', inprogress: false})
                })
        },
        fetchMaintenanceWorkOrdersChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceWorkOrdersFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceWorkOrders', args)
                .then((data) => {
                    commit('updateMaintenanceWorkOrders', data)
                    return dispatch('fetchMaintenanceWorkOrders', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceWorkOrders', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersChanged', inprogress: false})
                });
        },
        fetchMaintenanceWorkOrdersStatuses({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersStatuses', time: Date.now() / 1000})

                this.$api.maintenanceworkorders.getStatuses()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrdersStatuses', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersStatuses', inprogress: false})
                    });
            })
        },
        fetchMaintenanceWorkOrdersPriorities({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersPriorities', time: Date.now() / 1000})

                this.$api.maintenanceworkorders.getPriorities()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrdersPriorities', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersPriorities', inprogress: false})
                    });
            })
        },
        fetchMaintenanceWorkOrdersDiscounts({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersDiscounts', time: Date.now() / 1000})

                this.$api.maintenanceworkorders.getDiscounts()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrdersDiscounts', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersDiscounts', inprogress: false})
                    });
            })
        },
        fetchMaintenanceWorkOrdersTaxs({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersTaxs', time: Date.now() / 1000})

                this.$api.maintenanceworkorders.getTaxs()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrdersTaxs', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceWorkOrdersTaxs', inprogress: false})
                    });
            })
        },

        saveMaintenanceWorkOrder({dispatch}, maintenanceWorkOrder) {
            let fn = (maintenanceWorkOrder.id) ? 'updateMaintenanceWorkOrder' : 'createMaintenanceWorkOrder'
            return dispatch(fn, maintenanceWorkOrder);
        },
        createMaintenanceWorkOrder({commit, dispatch}, maintenanceWorkOrder) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_work_order')
                this.$api.maintenanceworkorders.create(maintenanceWorkOrder, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrder', response.data)
                            dispatch('fetchMaintenanceWorkOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceWorkOrder({commit, dispatch}, maintenanceWorkOrder) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_work_order')
                this.$api.maintenanceworkorders.update(maintenanceWorkOrder.id, maintenanceWorkOrder, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrder', response.data)
                            dispatch('fetchMaintenanceWorkOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceWorkOrder({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_work_order')
                this.$api.maintenanceworkorders.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceWorkOrder', id)
                            else commit('updateMaintenanceWorkOrder', response.data)
                            dispatch('fetchMaintenanceWorkOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenanceWorkOrder({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_work_order')
                this.$api.maintenanceworkorders.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrder', response.data)
                            dispatch('fetchMaintenanceWorkOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceWorkOrder({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_work_order')
                this.$api.maintenanceworkorders.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceWorkOrder', response.data)
                            dispatch('fetchMaintenanceWorkOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceWorkOrdersFullLoad(state, FullLoad) {
            state.maintenanceWorkOrdersFullLoad = state.maintenanceWorkOrdersFullLoad || FullLoad
        },
        setMaintenanceWorkOrdersLiteLoad(state, LitaLoad) {
            state.maintenanceWorkOrdersLiteLoad = state.maintenanceWorkOrdersLiteLoad || LitaLoad
        },

        updateMaintenanceWorkOrdersStatuses(state, nMaintenanceWorkOrdersStatuses) {
            state.maintenanceWorkOrdersStatuses = nMaintenanceWorkOrdersStatuses
        },
        updateMaintenanceWorkOrdersPriorities(state, nMaintenanceWorkOrdersPriorites) {
            state.maintenanceWorkOrdersPriorities = nMaintenanceWorkOrdersPriorites
        },
        updateMaintenanceWorkOrdersDiscounts(state, nMaintenanceWorkOrdersDiscount) {
            state.maintenanceWorkOrdersDiscounts = nMaintenanceWorkOrdersDiscount
        },
        updateMaintenanceWorkOrdersTaxs(state, nMaintenanceWorkOrdersTax) {
            state.maintenanceWorkOrdersTaxs = nMaintenanceWorkOrdersTax
        },

        setMaintenanceWorkOrders(state, nMaintenanceWorkOrders) {
            state.maintenanceWorkOrders = nMaintenanceWorkOrders
        },

        updateMaintenanceWorkOrders(state, nMaintenanceWorkOrders) {
            if (!state.maintenanceWorkOrders.length) {
                nMaintenanceWorkOrders = nMaintenanceWorkOrders.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceWorkOrders.sort(sortByName)
                state.maintenanceWorkOrders = nMaintenanceWorkOrders
                // const chunks = arraySplitIntoChunks(nMaintenanceWorkOrders)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceWorkOrders.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceWorkOrders.forEach(function (nMaintenanceWorkOrder) {
                if (nMaintenanceWorkOrder?.name) nMaintenanceWorkOrder.name_ = nMaintenanceWorkOrder.name.toLocaleLowerCase()
                let i = state.maintenanceWorkOrders.findIndex(u => (u.id == nMaintenanceWorkOrder.id))
                if (i < 0) {
                    state.maintenanceWorkOrders.push(nMaintenanceWorkOrder) //(Object.freeze(nMaintenanceWorkOrder))
                } else
                if (!state.maintenanceWorkOrdersFullLoad || state.maintenanceWorkOrders[i][changedField] != nMaintenanceWorkOrder[changedField]) {
                    updateObjectByDiff(state.maintenanceWorkOrders[i], nMaintenanceWorkOrder)
                    // delete nMaintenanceWorkOrder.id
                    // nMaintenanceWorkOrder = {...state.maintenanceWorkOrders[i], ...nMaintenanceWorkOrder}
                    // state.maintenanceWorkOrders[i] = nMaintenanceWorkOrder //Object.freeze(nMaintenanceWorkOrder)
                }
            })

        },
        filterMaintenanceWorkOrders(state, nMaintenanceWorkOrders) {
            // let Ids = state.maintenanceWorkOrders.map(u=> u.id)
            let nIds = nMaintenanceWorkOrders.map(u => u.id)
            let removedIds = state.maintenanceWorkOrders.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceWorkOrders.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceWorkOrders.splice(i, 1)
                }
            })
        },
        updateMaintenanceWorkOrder(state, nMaintenanceWorkOrder) {
            if (nMaintenanceWorkOrder?.name) nMaintenanceWorkOrder.name_ = nMaintenanceWorkOrder.name.toLocaleLowerCase()
            let i = state.maintenanceWorkOrders.findIndex(u => (u.id == nMaintenanceWorkOrder.id))
            if (i < 0) {
                state.maintenanceWorkOrders.push(nMaintenanceWorkOrder) //(Object.freeze(nMaintenanceWorkOrder))
            } else
            if (!state.maintenanceWorkOrdersFullLoad || state.maintenanceWorkOrders[i][changedField] != nMaintenanceWorkOrder[changedField]) {
                updateObjectByDiff(state.maintenanceWorkOrders[i], nMaintenanceWorkOrder)
                // delete nMaintenanceWorkOrder.id
                // nMaintenanceWorkOrder = {...state.maintenanceWorkOrders[i], ...nMaintenanceWorkOrder}
                // state.maintenanceWorkOrders[i] = nMaintenanceWorkOrder //Object.freeze(nMaintenanceWorkOrder)
            }
        },
        deleteMaintenanceWorkOrder(state, id) {
            let i = state.maintenanceWorkOrders.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceWorkOrders.splice(i, 1)
            }
        },

        clearMaintenanceWorkOrders(state) {
            state.maintenanceWorkOrders = []
            state.maintenanceWorkOrdersFullLoad = false
        },

        setItemConvertToWorkOrder(state, item) {
            state.itemConvertToWorkOrder = item
        }
    },
    getters: {
        getMaintenanceWorkOrdersDiscounts(state) {
            return state.maintenanceWorkOrdersDiscounts
        },
        getMaintenanceWorkOrdersTaxs(state) {
            return state.maintenanceWorkOrdersTaxs
        },
        getMaintenanceWorkOrdersStatuses(state) {
            return state.maintenanceWorkOrdersStatuses
        },
        getMaintenanceWorkOrdersPriorities(state) {
            return state.maintenanceWorkOrdersPriorities
        },
        isMaintenanceWorkOrdersFullLoad(state) {
            return state.maintenanceWorkOrdersFullLoad
        },
        isMaintenanceWorkOrdersLiteLoad(state) {
            return state.maintenanceWorkOrdersLiteLoad
        },
        maintenanceWorkOrders(state) {
            return state.maintenanceWorkOrders
        },
        maintenanceWorkOrdersByIds(state) {
            return state.maintenanceWorkOrders.reduce((maintenanceWorkOrdersByIds, maintenanceWorkOrder) => {
                maintenanceWorkOrdersByIds[maintenanceWorkOrder.id] = maintenanceWorkOrder
                return maintenanceWorkOrdersByIds
            }, {})
        },
        sortedMaintenanceWorkOrdersIds(state) {
            let maintenanceWorkOrders = state.maintenanceWorkOrders
            maintenanceWorkOrders.sort(sortByName)
            return maintenanceWorkOrders.map(u => u.id)
        },
        getItemConvertToWorkOrder(state) {
            return state.itemConvertToWorkOrder
        }
    }
}
