
export default {
	state: {
		unitsTypes: [],
	},
	actions: {
		fetchUnitsTypes({dispatch, commit, getters}) {
			return new Promise((resolve, reject) => {
				if (!getters.apiToken) {
					return reject(false)
				}
				dispatch('setLastCall', {name: 'fetchUnitsTypes', time: Date.now() / 1000})

				this.$api.unitshelper.getTypes()
					.then((response) => {
						if (response.status < 400 && !response.data?.error) {
							commit('updateUnitsTypes', response.data)
							resolve(response.data)
						} else {
							reject(response)
						}
					})
					.catch((error) => {
						reject(error)
						console.error(error);
					})
					.finally(() => {
						dispatch('setLastCall', {name: 'fetchUnitsTypes', inprogress: false})
					});
			})
		},
	},
	mutations: {
		updateUnitsTypes(state, unitsTypes) {
			state.unitsTypes = unitsTypes
		},
	},
	getters: {
		getUnitsTypes(state) {
			return state.unitsTypes
		},
	},
}