<template>
    <li class="car-info-dashboard__item">
        <div class="flex">
            <div class="car-info-dashboard__icon" :class="iconContainerClass" v-if="icon">
                <SvgIcon class="icon icon-color" :name="icon" />
                <SvgIcon v-if="statusIcon" class="icon icon-badge" :class="statusClass" :name="statusIcon"  />
            </div>
            <div class="car-info-dashboard__text-wrap">
                <div class="car-info-dashboard__title info-text sensors-title" :title="title">
                    {{  title }}:
                </div>
                <div class="car-info-dashboard__subtitle block-title sensors-value">
                    <span class="sensors-value__text" :title="valueText" v-if="valueText">{{ valueText }} :</span>
                    <span class="sensors-value__value" :title="value">{{value}}</span>
                </div>
            </div>
        </div>
        <div>
            <button class="button button_command car-info-dashboard__button" v-if="showButton" @click="$emit('buttonFn')">
                <span v-if="buttonText">{{buttonText}}</span>
                <SvgIcon :name="buttonIcon"
                         :title="buttonIconTitle"
                         class="icon"
                         v-if="buttonIcon"
                />
            </button>
        </div>
    </li>
</template>

<script>

export default {
    name: "UnitInfo__sensors_template",
    emits:['buttonFn'],
    props:{
        unitId: [String, Number],
        iconContainerClass: [String],
        icon: {
            type: [String, Boolean],
            default: () => false
        },

        statusClass: [String],
        statusIcon: [String],

        title: [String],
        value: [String, Number],
        valueText: [String, Number],

        showButton: {
            type: Boolean,
            default: () => false
        },
        buttonIcon: String,
        buttonIconTitle: String,
        buttonText: String,
    },
}
</script>

<style scoped lang="scss">
.car-info-dashboard {
    &__button {
        position: relative;
        top: -5px;
        min-width: 25px;
        min-height: 25px;
        width: 30px;
        height: 30px;
        &:hover {
            background: none;
            transform: scale(0.9);
        }
        .icon {
            width: 70%;
            height: 70%;
        }
    }
}
</style>