const RESOURCE_NAME = '/maintenance-contacts';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    removed(id, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/removed`, {}, {params});
    },
    restore(id, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/restore`, {}, {params});
    },
});