const RESOURCE_NAME = '/maintenance-work-orders';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    removed(id, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/removed`, {}, {params});
    },
    restore(id, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/restore`, {}, {params});
    },
    getStatuses() {
        return axios.get(`maintenance/work-order-statuses`);
    },
    getPriorities() {
        return axios.get(`maintenance/work-order-repair-priorities`);
    },
    getDiscounts() {
        return axios.get(`maintenance/work-order-discount-params`);
    },
    getTaxs() {
        return axios.get(`maintenance/work-order-tax-params`);
    },
});