<template>
    <UnitInfo__sensors_template
        :class="classForValue"
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Last message')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="value"
        :showButton="showButton"
        :buttonIcon="'document'"
        :buttonIconTitle="$t('Unit.History of recent messages')"
        @buttonFn="openLmsgsUnitHistory"
        v-if="_showByValue"
    />
    <!-- restoreUnit_Dialog popup -->
    <custom-modal v-if="modalWindows?.lmsgsUnitHistory" v-bind="modalWindows?.lmsgsUnitHistory?.props">
        <LastNMsgsOfUnitTable :unit-id="modalWindows?.lmsgsUnitHistory?.unitId || 0"/>
    </custom-modal>
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import LastNMsgsOfUnitTable from "@/components/units/tables/LastNMsgsOfUnitTable.vue";
import {mapGetters} from "vuex";
import {formatTimeAgo, isValueExist} from "@/lib/lib";
export default {
    inject: ['sensorsValues'],
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
        showButton: {
            type: Boolean,
            default: () => true,
        },
        showByValue: {
            type: Boolean,
            default: () => false,
        }
    },
    components:{
        UnitInfo__sensors_template,
        LastNMsgsOfUnitTable,
    },
    name: "UnitInfo__sensors_last_message",
    data() {
        return {
            modalWindows: {}
        }
    },
    computed:{
        ...mapGetters([
            'unitsLmsgsByIds',
            "unitsLmsgsTimeAgoByIds",
            "unitsByIds"
        ]),
        unit(){
          return this.unitsByIds[this.unitId]
        },
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        unitLmsgSensors() {
            return this.unitLmsg?.sensors || {}
        },
        unitLmsgTimeAgo() {
            return this.unitsLmsgsTimeAgoByIds[this.unitId]
        },
        value() {
            let timeAgo = this.unitLmsgTimeAgo
            if (!timeAgo && timeAgo !== 0) {
                return '';
            }
            let sign = ''
            if (timeAgo < 0) {
                sign = '-'
                timeAgo *= -1
            }
            //return timeAgo
            let time = formatTimeAgo(timeAgo)

            return time ? sign + this.$t('{time} ago', {time}) : ''
        },
        isValueExist() {
            return isValueExist(this.value)
        },
        _value() {
            return this.isValueExist ? this.value : '-'
        },
        classForValue() {
            return this.isValueExist ? this.$consts.classSensors.value : this.$consts.classSensors.no_value
        },
        _showByValue() {
            return this.showByValue ? this.isValueExist : true
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? 'unit_dashboard__massage' : false
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if(this.sensorsValues) {
                    this.sensorsValues.lmsg = val
                }
            },
            immediate: true
        },
    },
    methods:{
        modalClose() {
            this.modalWindows = {}
        },
        openLmsgsUnitHistory() {
            this.modalWindows = {
                'lmsgsUnitHistory': {
                    unitId: this.unitId,
                    props: {
                        title: this.unit?.name + ' - ' + this.$t('Unit.History of recent messages'),
                        btnOkClass: 'button_theme_neutral',
                        btnCanselClass: 'button_theme_danger',
                        btnOkText: this.$t('buttons.Refresh'),
                        buttons: [
                            {id: 'ok', fn: () => {
                                    this.modalWindows = {};
                                    this.$nextTick().then(() => {
                                        this.openLmsgsUnitHistory()
                                    })

                                }},
                            {id: 'cancel', fn: () => this.modalClose()},
                        ]
                    }
                }
            }
        },
    },
    beforeUnmount() {
        if(this.sensorsValues) {
            delete this.sensorsValues.lmsg
        }
    }
}
</script>

<style scoped>

</style>