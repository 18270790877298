// import {objectComparison} from "@/lib/lib";

import moment from "moment";

export default {
    state: {
        statistics: {
            users: [],
            units: [],
            units_activity: [],
            dns_activity: [],
            isLoaded: {
                units_activity: false,
                dns_activity: false,
            }
        },
        statisticsCalendar: [],
    },
    actions: {
        fetchStatisticsUsers({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    scale: args?.scale,
                    sub_users: args?.sub_users
                };
                let userId = args?.userId || getters.getAppUserId;
                let period = args?.period || 'this_month';

                this.$api.statistics.statisticsUsers(userId, period, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsUsers', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
        fetchStatisticsUnits({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    scale: args?.scale,
                    sub_users: args?.sub_users
                };
                let userId = args?.userId || getters.getAppUserId;
                let period = args?.period || 'this_month';

                this.$api.statistics.statisticsUnits(userId, period, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsUnits', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
        fetchStatisticsUnitsActivity({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    sub_users: args?.sub_users
                };
                let userId = args?.userId || getters.getAppUserId;
                let month = args?.month || moment(getters.getDateToday).format('YYYY-MM');

                commit('resetStatisticsUnitsActivity')
                this.$api.statistics.statisticsUnitsActivity(userId, month, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsUnitsActivity', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
        fetchStatisticsDNSActivity({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    sub_users: args?.sub_users
                };
                let userId = args?.userId || getters.getAppUserId;
                let month = args?.month || moment(getters.getDateToday).format('YYYY-MM');

                commit('resetStatisticsDNSActivity')
                this.$api.statistics.statisticsDNSActivity(userId, month, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsDNSActivity', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
        fetchStatisticsCalendar({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    scale: args?.scale,
                    //sub_users: args?.sub_users
                };
                //let userId = args?.userId || getters.getAppUserId;
                let period = args?.period || 'this_month';

                this.$api.statistics.statisticsCalendar(period, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsCalendar', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
    },
    mutations: {
        setStatisticsUsers(state, data) {
            state.statistics.users = data
        },
        setStatisticsUnits(state, data) {
            state.statistics.units = data
        },
        resetStatisticsUnitsActivity(state) {
            state.statistics.units_activity = []
            state.statistics.isLoaded.units_activity = false
        },
        setStatisticsUnitsActivity(state, data) {
            state.statistics.units_activity = data
            state.statistics.isLoaded.units_activity = true
        },
        resetStatisticsDNSActivity(state) {
            state.statistics.dns_activity = []
            state.statistics.isLoaded.dns_activity = false
        },
        setStatisticsDNSActivity(state, data) {
            state.statistics.dns_activity = data
            state.statistics.isLoaded.dns_activity = true
        },

        setStatisticsCalendar(state, data) {
            state.statisticsCalendar = data
        }
    },
    getters: {
        getStatisticsUsers(state) {
            return state.statistics.users
        },
        getStatisticsUnits(state) {
            return state.statistics.units
        },
        isStatisticsUnitsActivityLoaded(state) {
            return !!state.statistics.isLoaded.units_activity
        },
        getStatisticsUnitsActivity(state) {
            return state.statistics.units_activity
        },
        isStatisticsDNSActivityLoaded(state) {
            return !!state.statistics.isLoaded.dns_activity
        },
        getStatisticsDNSActivity(state) {
            return state.statistics.dns_activity
        },

        getStatisticsCalendar(state) {
            return state.statisticsCalendar
        },
    }
}
