const RESOURCE_NAME = '';//'/auth/';
import axios from "@/api/http-auth";
import config from "@/config";
const baseURL = config.api.baseURL

export default () => ({
    getObjects(params) {
        return axios.get(`${RESOURCE_NAME}/init`, {params, baseURL});
    },
    getUnitsLmsgs(params) {
        return axios.get(`${RESOURCE_NAME}/units-lmsgs`, {params, baseURL});
    },
    getUnits(params) {
        return axios.get(`${RESOURCE_NAME}/units`, {params, baseURL});
    },
    getDrivers(params) {
        return axios.get(`${RESOURCE_NAME}/drivers`, {params, baseURL});
    },
    getAppointments(params) {
        return axios.get(`${RESOURCE_NAME}/appointments`, {params, baseURL});
    },
    getUnitsGroups(params) {
        return axios.get(`${RESOURCE_NAME}/units-groups`, {params, baseURL});
    },
    getUsers(params) {
        return axios.get(`${RESOURCE_NAME}/users`, {params, baseURL});
    },
    getUsersGroups(params) {
        return axios.get(`${RESOURCE_NAME}/users-groups`, {params, baseURL});
    },
    getGeopoints(params) {
        return axios.get(`${RESOURCE_NAME}/geopoints`, {params, baseURL});
    },
    getGeopointsGroups(params) {
        return axios.get(`${RESOURCE_NAME}/geopoints-groups`, {params, baseURL});
    },
    getGeozones(params) {
        return axios.get(`${RESOURCE_NAME}/geozones`, {params, baseURL});
    },
    getGeozonesGroups(params) {
        return axios.get(`${RESOURCE_NAME}/geozones-groups`, {params, baseURL});
    },

    //getInsuranceReports(params) {
    //    return axios.get(`${RESOURCE_NAME}/insurance-reports`, {params, baseURL});
    //},
    //getInsuranceForms(params) {
    //    return axios.get(`${RESOURCE_NAME}/insurance-forms`, {params, baseURL});
    //},

    getShipments(params) {
        return axios.get(`${RESOURCE_NAME}/shipments`, {params, baseURL});
    },

    //maintenance
    getMaintenanceVendors(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_vendors`, {params, baseURL});
    },
    getMaintenanceContacts(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_contacts`, {params, baseURL});
    },
    getMaintenanceContactGroups(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_contact_groups`, {params, baseURL});
    },
    getMaintenanceExpenses(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_expenses`, {params, baseURL});
    },
    getMaintenanceHistorys(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_history`, {params, baseURL});
    },
    getMaintenanceInspections(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_inspections`, {params, baseURL});
    },
    getMaintenanceIssues(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_issues`, {params, baseURL});
    },
    getMaintenanceNotifications(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_notifications`, {params, baseURL});
    },
    getMaintenanceParts(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_parts`, {params, baseURL});
    },
    getMaintenanceServiceTasks(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_service_tasks`, {params, baseURL});
    },
    getMaintenanceWorkOrders(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance_work_orders`, {params, baseURL});
    },
    getMaintenance(params) {
        return axios.get(`${RESOURCE_NAME}/init?objs=maintenance`, {params, baseURL});
    },
});