<template>
    <ul class="route-tabs-list">
        <li v-for="item in list" :key="item.title">
            <router-link
                custom v-slot="{ isActive, href, navigate }"
                :to="parseRoute(item.route)"
                active-class="route-tabs-list__item--active"
                exact-active-class="route-tabs-list__item--active"
            >
                <a :href="href"
                   @click="navigate"
                   :class="isActive ? 'route-tabs-list__item--active' : ''"
                   class="route-tabs-list__item"
                >
                    {{ $te('RouteTabs.'+item.title) ? $t('RouteTabs.'+item.title) : item.title }}
                </a>
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: "RouteTabs",
    props: {
        list: [Array, Object]
    },
    methods: {
        parseRoute(route) {
            if(typeof route === 'function') {
                return route(this.$route);
            }
            return route
        }
    }
}
</script>

<style scoped lang="scss">
.route-tabs-list {
    background: var(--route-tabs-list-bg);
    box-shadow: var(--route-tabs-list-box-shadow);
    border-radius: 16px;
    display: flex;
    padding: 0 25px;

    &__item {
        display: flex;
        align-items: center;
        padding: 20px 10px;
        cursor: pointer;
        @include font(16px, normal, 500, none, normal, var(--route-tabs-list-color));
    }

    &__item--active {
        @include font(16px, normal, 700, none, normal, var(--route-tabs-list-color--active));
        border-bottom: solid 3px var(--route-tabs-list-border--active);
    }
}
</style>