import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        geopoints: [],
        geopointsFullLoad: false,
        geopointsLiteLoad: false,
    },
    actions: {
        fetchGeopoints/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGeopoints', time: Date.now() / 1000})

                const params = getApiProps('geopoints', args)
                this.$api.geopoints.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchGeopoints', inprogress: false})
                    });
            })
        },
        fetchGeopointsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getGeopoints()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setGeopoints', response.data)
                            commit('setGeopointsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeopointsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeopointsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isGeopointsLiteLoad) {
                    return resolve(getters.geopoints.length)
                }
                dispatch('fetchGeopoints', {lite: true})
                    .then((data) => {
                        commit('updateGeopoints', data)
                        commit('setGeopointsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeopointsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeopointsAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchGeopointsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchGeopointsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.geopoints.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.geopoints.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchGeopoints', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateGeopoints', data)
                            })
                            .catch(() => {
                                dispatch('fetchGeopoints', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setGeopointsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchGeopointsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchGeopointsChanged', inprogress: false})
                })
        },
        fetchGeopointsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isGeopointsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchGeopointsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchGeopoints', args)
                .then((data) => {
                    commit('updateGeopoints', data)
                    return dispatch('fetchGeopoints', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterGeopoints', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeopointsChanged', inprogress: false})
                });
        },
        reloadGeopointsAll({commit, dispatch}, args) {
            commit('clearGeopoints')
            return dispatch('fetchGeopointsAllLite', args)
                .then(() => {
                    dispatch('fetchGeopointsAllPages', args)
                })
        },

        saveGeopoint({dispatch}, geopoint) {
            let fn = (geopoint.id) ? 'updateGeopoint' : 'createGeopoint'
            return dispatch(fn, geopoint);
        },
        createGeopoint({commit, dispatch}, geopoint) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints')
                this.$api.geopoints.create(geopoint, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateGeopoint', {...response.data, points: geopoint.points})
                            dispatch('fetchGeopointsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateGeopoint({commit, dispatch}, geopoint) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints')
                this.$api.geopoints.update(geopoint.id, geopoint, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateGeopoint', {...response.data, points: geopoint.points})
                            dispatch('fetchGeopointsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteGeopoint({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.geopoints.delete(id)
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteGeopoint', id)
                            dispatch('fetchGeopointsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setGeopointsFullLoad(state, FullLoad) {
            state.geopointsFullLoad = state.geopointsFullLoad || FullLoad
        },
        setGeopointsLiteLoad(state, LitaLoad) {
            state.geopointsLiteLoad = state.geopointsLiteLoad || LitaLoad
        },

        setGeopoints(state, nGeopoints) {
            //state.geopoints = nGeopoints.map(g => Object.freeze(g))
            nGeopoints = nGeopoints.map(g => {
                if (g?.name) g.name_ = g.name.toLocaleLowerCase()
                return g //Object.freeze(u)
            })
            nGeopoints.sort(sortByName)
            state.geopoints = nGeopoints
        },

        updateGeopoints(state, nGeopoints) {
            if (!state.geopoints.length) {
                state.geopoints = nGeopoints.map(u => Object.freeze(u))
                return true
            }

            nGeopoints.forEach(function (nGeopoint) {
                let i = state.geopoints.findIndex(u => (u.id == nGeopoint.id))
                if (i < 0) {
                    state.geopoints.push(Object.freeze(nGeopoint))
                } else
                if (!state.geopointsFullLoad || state.geopoints[i][changedField] != nGeopoint[changedField]) {
                    // updateObjectByDiff(state.geopoints[i], nGeopoint)
                    delete nGeopoint.id
                    state.geopoints[i] = Object.freeze({...state.geopoints[i], ...nGeopoint})
                }
            })
        },
        filterGeopoints(state, nGeopoints) {
            let nId = nGeopoints.map(nu => nu.id)
            let removedIds = state.geopoints.filter(g => !nId.includes(g.id)).map(g => g.id)
            removedIds.forEach(removedId => {
                let i = state.geopoints.findIndex(g => (g.id == removedId))
                if (i != -1) {
                    state.geopoints.splice(i, 1)
                }
            })
        },
        updateGeopoint(state, nGeopoint) {
            let i = state.geopoints.findIndex(u => (u.id == nGeopoint.id))
            if (i < 0) {
                state.geopoints.push(Object.freeze(nGeopoint))
            } else
            if (!state.geopointsFullLoad || state.geopoints[i][changedField] != nGeopoint[changedField]) {
                // updateObjectByDiff(state.geopoints[i], nGeopoint)
                delete nGeopoint.id
                state.geopoints[i] = Object.freeze({...state.geopoints[i], ...nGeopoint})
            }
        },
        deleteGeopoint(state, id) {
            let i = state.geopoints.findIndex(g => (g.id == id))
            if (i != -1) {
                state.geopoints.splice(i, 1)
            }
        },
        clearGeopoints(state) {
            state.geopoints = []
            state.geopointsLiteLoad = false
            state.geopointsFullLoad = false
        },
    },
    getters: {
        isGeopointsLiteLoad(state) {
            return state.geopointsLiteLoad
        },
        isGeopointsFullLoad(state) {
            return state.geopointsFullLoad
        },
        geopoints(state) {
            return state.geopoints
        },
        geopointsByIds(state) {
            // return state.geopointsObj
            return state.geopoints.reduce((geopointsByIds, g) => {
                geopointsByIds[g.id] = g
                return geopointsByIds
            }, {})
        },
    }
}
