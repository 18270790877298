const RESOURCE_NAME = '/maintenance-history';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),

    servicesCreate(data, params) {
        return axios.post('maintenance-services/create', data, {params})
    },
    servicesUpdate(data, params) {
        return axios.post('maintenance-services/update', data, {params})
    },
    getHistoryAlgorithms() {
        return axios.get(`maintenance/history-algorithms`);
    },
    getHistoryStatuses() {
        return axios.get(`maintenance/history-statuses`);
    }
});