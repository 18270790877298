<template>
    <div class="aside-menu_wrap" :class="{'aside-menu_wrap__list': hasList}">
        <UserMenu class="menu__hamburger" v-if="!hasList && $consts.app.$isIntuit" :class="{'aside-menu_wrap-mobile':getMobileMenuOpen}"/>
        <aside class="section section_type_aside is_new-design" :class="{'aside-menu_wrap-mobile':getMobileMenuOpen}">
            <div class="menu">
                <div class="menu__hamburger" v-if="hasList && $consts.app.$isIntuit">
                    <button class="button button_view_icon button_theme_primary mtb__-4" @click="switchMainList">
                        <span class="icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__hamburger" /></span> </span>
                    </button>
                </div>
                <nav class="nav"><!--ToDo fix in component -->
                    <ul class="nav__list">
                        <template v-for="route in main_menus" :key="route.id">
                            <li class="nav__item item"
                                :class="[(getMainParentRouteName(route?.name) == getMainParentRouteName($route?.name)) && 'item_active router-link-exact-active' ]"
                                @click="onClickMainNavItem(route)"
                                @keypress.enter="onClickMainNavItem(route)"
                            >
                                <span v-if="route.badge && route.visible" class="nav__item__badge">{{ route.badge }}</span>
                                <div class="nav__icon-box">
                                    <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="route.icon" :title="$t(route.title)"/></span> </span>
                                    <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" :name="route.icon+'_fill'" :title="$t(route.title)"/></span> </span>
                                </div>
                                <span class="nav__title title">{{ route.title ? $t(route.title) : route.name }}</span>
                                <div class="nav__decor decor"></div>
                                <div class="nav__decor2 decor2"></div>
                            </li>
                        </template>

                        <template v-if="hasMoreMenu">
                            <!-- template -->
                                <!-- https://next.router.vuejs.org/api/#router-link-s-v-slot -->
                                <li class="nav__item item"
                                    :class="[(getMainParentRouteName(replaceable.name) == getMainParentRouteName(route_name)) && 'item_active router-link-exact-active']"
                                    @click="onClickMainNavItem(replaceable)"
                                    @keypress.enter="onClickMainNavItem(replaceable)"
                                    v-if="replaceable"
                                >
                                    <div class="nav__icon-box" >
                                        <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="replaceable.icon" /></span> </span>
                                        <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" :name="replaceable.icon+'_fill'" /></span> </span>
                                    </div>
                                    <span class="nav__title title">{{replaceable.title ? $t(replaceable.title) : replaceable.name}}</span>
                                    <div class="nav__decor decor"></div>
                                </li>
                            <!-- /template -->

                            <li class="nav__item item item_type_more"
                                @mouseover="showItemTypeMore = true"
                                @mouseout="showItemTypeMore = false"
                            >
                                <div class="nav__icon-box">
                                    <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__more" /></span> </span>
                                    <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__more_fill" /></span> </span>
                                </div>
                                <span class="nav__title title">{{ $t('menu.More') }}</span>
                                <div class="nav__decor decor"></div>

                                <div class="nav__dropdown-menu dropdown-menu position_left-center" data-type=""
                                     :class="{'dropdown-menu_open': showItemTypeMore}"
                                >
                                    <div class="dropdown-menu__wrap">
                                        <ul class="dropdown-menu__list">
                                            <!-- template -->
                                                <li class="dropdown-menu__item item "
                                                    v-for="route in more_menus"
                                                    :key="route.id"
                                                    :class="[(getMainParentRouteName(route.name) == getMainParentRouteName(route_name)) && 'item_active router-link-exact-active']"
                                                    @click="onClickDropdownNavItem(route)"
                                                    @keypress.enter="onClickDropdownNavItem(route)"
                                                    v-show="route.name !== replaceable.name"
                                                >
                                                    <span class="dropdown-menu__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="route.icon" /></span> </span>
                                                    <span class="dropdown-menu__item-name">{{route.title ? $t(route.title) : route.name}}</span>
                                                </li>
                                            <!-- /template -->
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </nav>
            </div>
            <div class="aside__info info">
                <SectionConnection />
                <SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getTimeFormat"/>
            </div>
        </aside>
    </div>

</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import SectionConnection from "@/components/_base/SectionConnection.vue";
    import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";
    import UserMenu from "@/components/app/UserMenu.vue";
    import {getMainParentRouteName} from "@/lib/lib";

    export default {
        name: "SectionAside",
        inject:['sectionGlobalClass'],
        props: [],
        data() {
            return {
                getMainParentRouteName,
                showItemTypeMore: false,
                mainSize: this.$consts.menus.mainSize,
                replaceable: null,
            }
        },
        components: {
            UserMenu,
            SectionCurrentDateTime,
            SectionConnection
        },
        computed: {
            ...mapGetters([
                "getAppUserId",
                "getAppMenus",
                "getMobileMenuOpen",
                "getUserTimeZone",
                "getTimeFormat",
                "getUserStorageKey",

                "getNewEventsCount",
                "getNewVideoEventsCount",
                "getNewNotificationEventsCount",

                "getTimeToday",
                "getLastVisitNotifications",
            ]),
            isPrometheusAdmin() {
                return this.$isPrometheus && this.$isAdminSite
            },
            routeMeta() {
                return this.$route.meta
            },
            route_name(){
                return this.$route.name
            },
            mainRouteName() {
                return this.routeMeta.mainRouteName
            },
            routeFilter(){
                return this.$route.params
            },
            setRoute() {
                return this.routeMeta?.setRoute
            },
            hasMoreMenu(){
                return (this.getAppMenus.length > this.mainSize)
            },
            // mainSize(){
            //     // return this.$consts.menus.mainSize
            //     return this.$isIntuit ? 8 : 5
            // },
            main_menus(){
                return this.getAppMenus.slice(0,this.mainSize).map(m => {
                    let extraOptionsMenuItem = this.extraOptionsMenu[m.id]
                    return {...m, ...extraOptionsMenuItem}
                })
            },
            more_menus(){
                return this.getAppMenus.slice(this.mainSize)
            },
            route_path(){
                return this.$route.path
            },
            menuIndex() {
                return this.main_menus.findIndex(m => m.id == this.$route.meta?.mainRouteName?.toLowerCase())
            },
            hasList(){
                return !!this.$route.meta.list
            },
            notifications(){
                let count = this.getNewEventsCount + this.getNewNotificationEventsCount + this.getNewVideoEventsCount
                let plus = (this.getTimeToday > this.getLastVisitNotifications ? '+' : '')
                return (count || plus) ? count + plus : false
            },
            extraOptionsMenu(){
                return {
                    'notificationsevents': {
                        badge: this.notifications,
                        visible: this.mainRouteName != this.$consts.routerNames.notificationsevents.main
                    }
                }
            }
        },
        watch: {
            menuIndex:{
                handler: function (index) {
                    let clss = (index === 0 ? 'first' : (index === this.main_menus.length - 1) ? 'last' : '')
                    this.sectionGlobalClass.class = clss ? 'manu-index-'+clss : ''
                }
            },
            // route_path: {
            //     handler: function (newVl, oldVl) {
            //         if(newVl !== oldVl){
            //             if(this.$isDevelopment) console.log('route_path', oldVl, '-->', newVl)
            //             this.setRoutePath();
            //         }
            //     }
            // },
            hasMoreMenu:{
                handler: function (newVL){
                    if(newVL && !this.replaceable) {
                        this.replaceable = this.getAppMenus[this.mainSize]
                    }
                }
            }
        },
        methods: {
            ...mapMutations([
            ]),
            switchMainList(){
                let lite = this.route_name?.split('.')?.includes('lite')
                let noList = this.routeFilter.noList
                this.setRoute.liteTemplate(this.$route, this.$router, !noList && !lite, !noList)
            },
            onClickMainNavItem: function (route) {
                if(!route.name) return false;
                // if(this.route_name !== route.name) {
                    this.$router.push({name: route.name})
                // }
            },
            onClickDropdownNavItem: function (route) {
                if(!route) return false
                this.showItemTypeMore = false
                this.replaceable = route
                if(this.route_name !== route.name) {
                    this.$router.push({name: route.name})
                }
            },
        },
        created() {
        },
        mounted() {
        },
        updated() {
        }
    }
</script>

<style lang="scss" scoped>
.aside-menu_wrap{
    display: grid; height: 100vh;
    grid-template-rows: auto 1fr auto;
    &__list{
        grid-template-rows: auto;
    }
    .aside-relative{
        position: relative;
    }
}
.section {
    &.section_type_aside {
        z-index: 5;
        display: grid;
        flex-shrink: 0;
        grid-template-rows: 1fr auto;
        //display: none;
        width: 6.5rem;
        height: 100%;
        padding: 0;
        //overflow: hidden;
        overflow: visible;
        overflow-y: auto;
        background: var(--menu-background);
        transition: .3s;
        @include scroll(var(--color-white), var(--color-neutral-100), 5px);
        &:hover {
            @include scroll(var(--color-white), var(--color-neutral-150), 5px);
        }

        .menu {
            width: 100%;
            //overflow: hidden;
            max-width: 6.5rem;
        }

        .aside__info {
            //background: red;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
.is_new-design{
    .menu {
        height: 100%;

        &__hamburger {
            //background: red;
            display: grid;
            align-items: center;
            justify-content: center;
            height: 4rem;
        }

        &__user {
            display: grid;
            align-items: center;
            justify-content: center;
            height: 4rem;
            position: relative;
            width: 2.5rem;
            margin: 0 auto;
        }
    }
    .nav {
        //background: red;

        &__list {
            padding-left: .125rem;
            overflow: visible;

            &.list_status_replaceable-active {
                .item {
                    &.item_type_more {
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &.list_status_before-replaceable-active {
                .item {
                    &.item_status_replaceable {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        &__item {
            $backgroundHover: linear-gradient(to right, var(--color-primary-50), rgba(var(--color-neutral-25-rgb), .5));

            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 4.5rem;
            padding-right: .125rem;
            padding-bottom: .25rem;
            cursor: pointer;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            border-radius: var(--border-radius-xl) 0 0 var(--border-radius-xl);
            //transition: all var(--transition-duration-md) ease-in-out;
            &__badge{
                position: absolute;
                top: 12px;
                left: calc(50% + .1rem);
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 1.125rem;
                height: 1.125rem;
                padding: 0 0.1875rem;
                text-shadow: 0 1px 3px rgb(0 0 0 / 25%);
                background: var(--color-secondary-600);
                border-radius: 0.5625rem;
                font-family: "Open Sans", sans-serif;
                color: var(--color-white);
                font-size: 12px;
                line-height: 16px;
                font-weight: 700;
                font-style: normal;
                text-transform: none;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 50%;
                width: calc(100% - 2.75rem);
                height: 1px;
                content: '';
                background: var(--menu-line);
                //background: red;
                transition: all var(--transition-duration-md) ease-in-out;
                transform: translate(-50%, 0);
            }

            &:last-child {
                &:after {
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    width: calc(100% - 2.75rem);
                    height: 1px;
                    content: '';
                    background: var(--color-neutral-100);
                    //background: red;
                    transition: all var(--transition-duration-md) ease-in-out;
                    transform: translate(-50%, 0);
                }

                &:hover {
                    &:after {
                        opacity: 0;
                    }
                }
            }

            &:hover{
                z-index: 0;
                .decor {
                    display: initial;
                    background: $backgroundHover;
                }
                &:first-child{
                    .decor {
                        top:calc(50% + 0.5rem);
                        height: calc(100% + 1rem);
                        &:before{
                            display: none;
                        }
                    }
                }
            }

            &.item_active {
                position: relative;
                z-index: 1;
                background: linear-gradient(to right, var(--color-primary-75), var(--color-neutral-25));

                &:before,
                &:after {
                    //display: none;
                }

                & + .item {
                    &:before {
                        //display: none;
                    }
                }

                .nav__icon {
                    opacity: 0;

                    &.icon_active {
                        opacity: 1;
                    }
                }

                .title {
                    color: var(--menu-active);
                }

                .decor {
                    display: initial;
                }

                &:hover {
                    background: linear-gradient(to right, var(--color-primary-75), var(--color-neutral-25));
                    .decor {
                        background: linear-gradient(to right, var(--color-primary-75), var(--color-neutral-25));
                    }
                }
            }

            &.item_status_hidden {
                display: none;
            }

            &.item_type_more {
                &:hover {
                    .nav__icon {
                        opacity: 0;

                        &.icon_active {
                            opacity: 1;
                        }
                    }

                    .title {
                        color: var(--menu-active);
                    }

                    .dropdown-menu {
                        //display: block;
                    }
                }

                .dropdown-menu__wrap {
                    cursor: initial;
                    //background: red;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: -.5rem;
                        z-index: 0;
                        width: .5rem;
                        height: 100%;
                        cursor: initial;
                        content: '';
                        background: var(--color-white);
                        opacity: 0;
                        //transform: translate(0, -50%);
                    }
                }
            }

            &:first-child {
                &.item_active {
                    .decor {
                        top: 0;
                        height: calc(100% + 1rem);
                        transform: translate(0, 0);

                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &:hover {
                //background: linear-gradient(to right, var(--color-primary-50), var(--color-neutral-25));
                background: $backgroundHover;
                //background: linear-gradient(to right, rgba(var(--color-primary-75-rgb), .5), var(--color-neutral-25));

                &:before {
                    opacity: 0;
                }

                & + .item {
                    &:before {
                        opacity: 0;
                    }
                }

                .nav__title {
                    //width: 100%;
                }
            }
        }

        &__icon-box {
            width: 2rem;
            height: 2rem;
            //background: red;
        }

        &__icon {
            position: absolute;
            margin-bottom: .125rem;
            transition: all var(--transition-duration-md) ease-in-out;

            &.icon_active {
                opacity: 0;

                .icon__symbol {
                    svg {
                        color: var(--menu-active);
                    }
                }
            }

            .icon__symbol {
                svg {
                    color: var(--menu-color-default);
                }
            }
        }

        &__title {
            display: block;
            width: 78%;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            @include font(10px, 12px, 700, none, normal, var(--menu-color-default));
            font-family: "Open Sans", sans-serif;
        }

        &__decor {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: -1;
            display: none;
            width: calc(100% - 1rem);
            height: calc(100% + 2rem);
            background: linear-gradient(to left, var(--color-neutral-25), var(--color-primary-75) 70px);
            transform: translate(0, -50%);

            &:before,
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 1rem;
                content: '';
                background: var(--color-white);
                border-radius: 0 0 var(--border-radius-xl) 0;
                transition: all var(--transition-duration-md) ease-in-out;
            }

            &:after {
                top: initial;
                bottom: 0;
                border-radius: 0 var(--border-radius-xl) 0 0;
            }
        }

        &__decor2 {
            display: none;
        }

        .item_active + .nav__item:hover {
            z-index: 2;
            .decor{
                top:calc(50% + 0.5rem);
                height: calc(100% + 1rem);
                &:before{
                    display: none;
                }
            }
        }
        .nav__item:hover + .item_active {
            .decor{
                top:calc(50% + 0.5rem);
                height: calc(100% + 1rem);
                &:before{
                    display: none;
                }
            }
        }
    }
}
.prometheus-admin {
    .section_type_aside {
        z-index: 5;
        display: grid;
        flex-shrink: 0;
        grid-template-rows: 1fr auto;
        //display: none;
        width: 6.5rem;
        height: 100%;
        padding: 1rem 0 0 0;
        //overflow: hidden;
        overflow: visible;
        overflow-y: auto;
        background: var(--color-blue-menu);
        transition: .3s;
        @include scroll(var(--color-white), var(--color-neutral-100), 5px);
        &:hover {
            @include scroll(var(--color-white), var(--color-neutral-150), 5px);
        }

        /*.aside__time-box {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.75rem;
          //background: red;
        }

        .aside__time {
          @include font(14px, 20px, 600, none, normal, var(--color-font));
        }*/

        .menu {
            width: 100%;
            //overflow: hidden;
            max-width: 6.5rem;
        }

        .aside__info {
            //background: red;
            display: flex;
            flex-direction: column;
            align-items: center;

            .info {
                &__connect {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    &.connect_status_no-connection {
                        .icon_connection {
                            display: none;
                        }

                        .icon_no-connection {
                            display: initial;
                        }
                    }

                    .icon_connection,
                    .icon_no-connection {
                        margin-left: 1px;
                    }

                    .icon_no-connection {
                        display: none;
                    }
                }
                &__time {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding-bottom: .6875rem;
                    padding-top: .1875rem;
                    @include font(14px, 20px, 600, none, normal, var(--color-white));

                    &.time_format_12-hour {
                        font-size: 12px;
                    }
                }

                &__connect {
                    padding-top: 1.1875rem;
                }
            }
            .icon .icon__symbol svg{
                color: var(--color-white);
            }
        }
    }
    .nav {
        &__decor {
            width: calc(100% - 2rem);
        }
        &__item {
            border-radius: var(--border-radius-xxl) 0 0 var(--border-radius-xxl);
            &::before {
                content: none;
            }
            &:hover {
                .decor {
                    display: none;
                }
            }
            &:first-child{
                &.item_active {
                    .decor {
                        top: -1rem;
                        height: calc(100% + 2rem);
                        transform: translate(0, 0);

                        &:before {
                            top: 0;
                            display: block;
                            background: var(--color-blue-menu);
                        }
                    }
                }
            }
            &:last-child{
                &::after {
                    content: none;
                }
            }
            .title {
                color: white;
            }
            &.item_active{
                .title {
                    color: #262B46;
                }
                .decor{
                    &:after,
                    &:before{
                        background: var(--color-blue-menu);
                    }
                }
            }
        }

        &__icon {
            position: absolute;
            margin-bottom: .125rem;
            transition: all var(--transition-duration-md) ease-in-out;

            &.icon_active {
                opacity: 0;

                .icon__symbol {
                    svg {
                        color: #262B46;
                    }
                }
            }

            .icon__symbol {
                svg {
                    color: var(--color-white);
                }
            }
        }
    }
}
.prometheus-portal {
    background: var(--unit-info-border);
    .section {
        &.section_type_aside {
            width: 100%;
            .aside__info {
                .info {
                    &__time {
                        &.time_format_12-hour {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
    .nav {
        &__item {
            gap: 3px;
            background: none;
            &:before {
                width: 70%;
            }
            &:last-child:after {
                width: 70%;
            }
            .decor {
                display: none !important;
            }
            &:hover,
            &.item_active {
                background: none;
                &:hover {
                    background: none;
                }
                .decor2 {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        border: 5px solid transparent;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:after {
                        left: 0;
                        border-left: 5px solid var(--menu-active);
                    }
                    &:before {
                        right: 0;
                        border-right: 5px solid var(--menu-active);
                    }
                }
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
                & + .item {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &__icon-box {
            $iconSize: 28px;
            width: $iconSize;
            height: $iconSize;
            .icon.icon_size_32 .icon__symbol svg {
                width: $iconSize;
                height: $iconSize;
            }
        }
    }
}
</style>

<style lang="scss">
.time {
    @include font(14px, 20px, 600, none, normal, var(--menu-color-default));
}
.section {
    &.section_type_aside {
        .aside__info {
            .info {
                &__connect {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    &.connect_status_no-connection {
                        .icon_connection {
                            display: none;
                        }

                        .icon_no-connection {
                            display: initial;
                        }
                    }

                    .icon_connection,
                    .icon_no-connection {
                        margin-left: 1px;
                    }

                    .icon_no-connection {
                        display: none;
                    }
                }
                &__time {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding-bottom: .6875rem;
                    padding-top: .1875rem;
                    @include font(14px, 20px, 600, none, normal, var(--menu-color-default));

                    &.time_format_12-hour {
                        font-size: 12px;
                    }
                }

                &__connect {
                    padding-top: 1.1875rem;
                }
            }
        }
    }
}
</style>