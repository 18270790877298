// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {
    statistics: (route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.statistics.main) {
            if(this.$isDevelopment) console.warn('statistics', route);
            return false
        }
        router.push({
            name: consts.routerNames.statistics.main,
            params: {},
            query: {}
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    {
        path: 'statistics',
        name: consts.routerNames.statistics.main,
        meta: {
            mainRouteName: consts.routerNames.statistics.main,
            bodyGrid: "1X1",
            setRoute: defaultSetRoute,
        },
        component: () => import('@/views/intuit/admin/StatisticsPage.vue'),
        children: [
        ]
    },
];

export default routes;