const RESOURCE_NAME = '/statistics';

export default axios => ({
    statisticsCalendar(period, params) {
        return axios.get(`${RESOURCE_NAME}/calendar/${period}`, {params});
    },
    statisticsUnits(userId, period, params) {
        //if(!period) period = 'now'
        return axios.get(`${RESOURCE_NAME}/units/${userId}/${period}`, {params});
    },
    statisticsUsers(userId, period, params) {
        //if(!period) period = 'now'
        return axios.get(`${RESOURCE_NAME}/users/${userId}/${period}`, {params});
    },
    statisticsUnitsActivity(userId, month, params) {
        return axios.get(`${RESOURCE_NAME}/units-activity/${userId}/${month}`, {params});
    },
    statisticsDNSActivity(userId, month, params) {
        return axios.get(`${RESOURCE_NAME}/dns-activity/${userId}/${month}`, {params});
    },
});