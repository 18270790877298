// import store from '@/store'
// import consts from "@/consts";
import units from '@/router/intuit/portal/units.js';
import tracks from '@/router/intuit/portal/tracks.js';
import geoitems from '@/router/intuit/portal/geoitems.js';
import notifications from '@/router/intuit/portal/notifications.js';
import dealer from '@/router/intuit/portal/dealer.js';
import help from '@/router/intuit/portal/help.js';
import dashboard from '@/router/intuit/portal/dashboard.js';
import locator from '@/router/intuit/portal/locator.js';
import analytics from '@/router/intuit/portal/analytics.js';
import md from '@/router/intuit/portal/md.js';
import reports from '@/router/intuit/portal/reports.js';
import shipment from '@/router/intuit/portal/shipment.js';

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    ...units,
    ...tracks,
    ...geoitems,
    ...notifications,
    ...dealer,
    ...help,
    ...dashboard,
    ...locator,
    ...analytics,
    ...md,
    ...reports,
    ...shipment,
]

export default routes