<template>
    <h5 class="list__address">{{address}}</h5>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_address',
        props: [
            'unitId'
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsLmsgsByIds",
                "unitsByIds",
            ]),
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId] || {}
            },
            unit() {
                return this.unitsByIds[this.unitId]
            },
            coords() {
                return 'lat: ' + (this.unit?.coords?.lat || null) + ' lng:' + (this.unit?.coords?.lng || null)
            },
            address(){
                return this.unitLmsg?.latlng?.lat || this.unitLmsg?.latlng?.lng ? this.unitLmsg?.address : this.coords
            },
        },
        updated: function() {
           // console.log(`UnitsList_unit_address ${this.address} updated`);
        },
    }
</script>