// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        maintenanceServiceTasksFullLoad: false,
        maintenanceServiceTasksLiteLoad: false,
        maintenanceServiceTasks: [],
    },
    actions: {
        fetchMaintenanceServiceTasks/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasks', time: Date.now() / 1000})

                const params = getApiProps('maintenance_service_tasks', args)
                this.$api.maintenanceservicetasks.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasks', inprogress: false})
                    });
            })
        },
        fetchMaintenanceServiceTasksAllInit({/*dispatch,*/ commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceServiceTasksLiteLoad) {
                    return resolve(getters.maintenanceServiceTasks.length)
                }
                this.$api.init.getMaintenanceServiceTasks()
                    .then((response) => {
                        if (response.status < 400 && response.data?.maintenance_service_tasks) {

                            commit('setMaintenanceServiceTasks', response.data.maintenance_service_tasks)
                            commit('setMaintenanceServiceTasksLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        //setTimeout(() => {
                        //    dispatch('fetchMaintenanceServiceTasksAllInit', {})
                        //}, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceServiceTasksAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isMaintenanceServiceTasksLiteLoad) {
                    return resolve(getters.maintenanceServiceTasks.length)
                }
                dispatch('fetchMaintenanceServiceTasks', {lite: true})
                    .then((data) => {
                        commit('updateMaintenanceServiceTasks', data)
                        commit('setMaintenanceServiceTasksLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchMaintenanceServiceTasksAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchMaintenanceServiceTasksAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasksAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasksChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.maintenanceServiceTasks.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.maintenance.pageSize
                    let items = getters.maintenanceServiceTasks.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchMaintenanceServiceTasks', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateMaintenanceServiceTasks', data)
                            })
                            .catch(() => {
                                dispatch('fetchMaintenanceServiceTasks', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setMaintenanceServiceTasksFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasksAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasksChanged', inprogress: false})
                })
        },
        fetchMaintenanceServiceTasksChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isMaintenanceServiceTasksFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasksChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchMaintenanceServiceTasks', args)
                .then((data) => {
                    commit('updateMaintenanceServiceTasks', data)
                    return dispatch('fetchMaintenanceServiceTasks', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterMaintenanceServiceTasks', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchMaintenanceServiceTasksChanged', inprogress: false})
                });
        },

        saveMaintenanceServiceTask({dispatch}, maintenanceServiceTask) {
            let fn = (maintenanceServiceTask.id) ? 'updateMaintenanceServiceTask' : 'createMaintenanceServiceTask'
            return dispatch(fn, maintenanceServiceTask);
        },
        createMaintenanceServiceTask({commit, dispatch}, maintenanceServiceTask) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service_tasks')
                this.$api.maintenanceservicetasks.create(maintenanceServiceTask, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceServiceTask', response.data)
                            dispatch('fetchMaintenanceServiceTasksChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateMaintenanceServiceTask({commit, dispatch}, maintenanceServiceTask) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service_tasks')
                this.$api.maintenanceservicetasks.update(maintenanceServiceTask.id, maintenanceServiceTask, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceServiceTask', response.data)
                            dispatch('fetchMaintenanceServiceTasksChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteMaintenanceServiceTask({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service_tasks')
                this.$api.maintenanceservicetasks.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteMaintenanceServiceTask', id)
                            else commit('updateMaintenanceServiceTask', response.data)
                            dispatch('fetchMaintenanceServiceTasksChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedMaintenanceServiceTask({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service_tasks')
                this.$api.maintenanceservicetasks.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceServiceTask', response.data)
                            dispatch('fetchMaintenanceServiceTasksChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreMaintenanceServiceTask({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('maintenance_service_tasks')
                this.$api.maintenanceservicetasks.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateMaintenanceServiceTask', response.data)
                            dispatch('fetchMaintenanceServiceTasksChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setMaintenanceServiceTasksFullLoad(state, FullLoad) {
            state.maintenanceServiceTasksFullLoad = state.maintenanceServiceTasksFullLoad || FullLoad
        },
        setMaintenanceServiceTasksLiteLoad(state, LitaLoad) {
            state.maintenanceServiceTasksLiteLoad = state.maintenanceServiceTasksLiteLoad || LitaLoad
        },

        setMaintenanceServiceTasks(state, nMaintenanceServiceTasks) {
            state.maintenanceServiceTasks = nMaintenanceServiceTasks
        },

        updateMaintenanceServiceTasks(state, nMaintenanceServiceTasks) {
            if (!state.maintenanceServiceTasks.length) {
                nMaintenanceServiceTasks = nMaintenanceServiceTasks.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nMaintenanceServiceTasks.sort(sortByName)
                state.maintenanceServiceTasks = nMaintenanceServiceTasks
                // const chunks = arraySplitIntoChunks(nMaintenanceServiceTasks)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.maintenanceServiceTasks.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nMaintenanceServiceTasks.forEach(function (nMaintenanceServiceTask) {
                if (nMaintenanceServiceTask?.name) nMaintenanceServiceTask.name_ = nMaintenanceServiceTask.name.toLocaleLowerCase()
                let i = state.maintenanceServiceTasks.findIndex(u => (u.id == nMaintenanceServiceTask.id))
                if (i < 0) {
                    state.maintenanceServiceTasks.push(nMaintenanceServiceTask) //(Object.freeze(nMaintenanceServiceTask))
                } else
                if (!state.maintenanceServiceTasksFullLoad || state.maintenanceServiceTasks[i][changedField] != nMaintenanceServiceTask[changedField]) {
                    updateObjectByDiff(state.maintenanceServiceTasks[i], nMaintenanceServiceTask)
                    // delete nMaintenanceServiceTask.id
                    // nMaintenanceServiceTask = {...state.maintenanceServiceTasks[i], ...nMaintenanceServiceTask}
                    // state.maintenanceServiceTasks[i] = nMaintenanceServiceTask //Object.freeze(nMaintenanceServiceTask)
                }
            })

        },
        filterMaintenanceServiceTasks(state, nMaintenanceServiceTasks) {
            // let Ids = state.maintenanceServiceTasks.map(u=> u.id)
            let nIds = nMaintenanceServiceTasks.map(u => u.id)
            let removedIds = state.maintenanceServiceTasks.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.maintenanceServiceTasks.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.maintenanceServiceTasks.splice(i, 1)
                }
            })
        },
        updateMaintenanceServiceTask(state, nMaintenanceServiceTask) {
            if (nMaintenanceServiceTask?.name) nMaintenanceServiceTask.name_ = nMaintenanceServiceTask.name.toLocaleLowerCase()
            let i = state.maintenanceServiceTasks.findIndex(u => (u.id == nMaintenanceServiceTask.id))
            if (i < 0) {
                state.maintenanceServiceTasks.push(nMaintenanceServiceTask) //(Object.freeze(nMaintenanceServiceTask))
            } else
            if (!state.maintenanceServiceTasksFullLoad || state.maintenanceServiceTasks[i][changedField] != nMaintenanceServiceTask[changedField]) {
                updateObjectByDiff(state.maintenanceServiceTasks[i], nMaintenanceServiceTask)
                // delete nMaintenanceServiceTask.id
                // nMaintenanceServiceTask = {...state.maintenanceServiceTasks[i], ...nMaintenanceServiceTask}
                // state.maintenanceServiceTasks[i] = nMaintenanceServiceTask //Object.freeze(nMaintenanceServiceTask)
            }
        },
        deleteMaintenanceServiceTask(state, id) {
            let i = state.maintenanceServiceTasks.findIndex(u => (u.id == id))
            if (i != -1) {
                state.maintenanceServiceTasks.splice(i, 1)
            }
        },

        clearMaintenanceServiceTasks(state) {
            state.maintenanceServiceTasks = []
            state.maintenanceServiceTasksFullLoad = false
        },
    },
    getters: {
        isMaintenanceServiceTasksFullLoad(state) {
            return state.maintenanceServiceTasksFullLoad
        },
        isMaintenanceServiceTasksLiteLoad(state) {
            return state.maintenanceServiceTasksLiteLoad
        },
        maintenanceServiceTasks(state) {
            return state.maintenanceServiceTasks
        },
        maintenanceServiceTasksByIds(state) {
            return state.maintenanceServiceTasks.reduce((maintenanceServiceTasksByIds, maintenanceServiceTask) => {
                maintenanceServiceTasksByIds[maintenanceServiceTask.id] = maintenanceServiceTask
                return maintenanceServiceTasksByIds
            }, {})
        },
        sortedMaintenanceServiceTasksIds(state) {
            let maintenanceServiceTasks = state.maintenanceServiceTasks
            maintenanceServiceTasks.sort(sortByName)
            return maintenanceServiceTasks.map(u => u.id)
        },
    }
}
