<template>
    <div class="list__item item_view_unit item_view_unit__xtag"
         :class="{
            'item_active': isActive,
            'item_view_unit__group': childrenUnits.length
         }"
         :data-group-id="groupId" :data-unit-id="unitId"
         @click.stop="setUnitActive"
         @mouseover="showMenu = true" @mouseleave="showMenu = false"
    >
        <UnitsList_unit_icons :unitId="unitId" :indicators="indicators" :default-icon="'bluetooth'"/>

        <div class="list__title-line">
            <h4 class="list__title">{{unitName}}</h4>
            <div class="list__title-line">
                <UnitsList_unit__status_battery :unitId="unitId"/>
                <UnitsList_unit_timeago :unit-id="unitId" v-if="false"/><!-- ! -->
            </div>
        </div>
        <div class="list__address-line">
            <UnitsList_unit_address :unit-id="unitId" v-if="false"/><!-- ! -->
            <UnitsList_unit__status :unit-id="unitId" v-if="false"/><!-- ! -->
            <UnitsList_unit_sensors :sensors="['temperature', 'humidity', 'shock']" />
        </div>
        <div class="item_view_unit__dropdown" v-if="childrenUnits.length">
            <button class="button button_command" @click="showListParams = !showListParams">
                <SvgIcon :name="showListParamsButtonIcon" class="icon"/>
            </button>
        </div>
        <UnitsList_unit_menu
            :unit-id="unitId"
            v-if="false"
        />
<!--        (showMenu || modalsWindows?.popupOutput )-->
    </div>
    <div v-show="showListParams">
        <template v-for="unit in childrenUnits" :key="unit.unitId">
            <component :is="'UnitsList_unit'+unit.type" v-bind="unit"/>
        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    import UnitsList_unit_timeago from "@/components/units/UnitsList_unit_timeago.vue";
    import UnitsList_unit_address from "@/components/units/UnitsList_unit_address.vue";
    import UnitsList_unit__status from "@/components/units/UnitsList_unit__status.vue";
    import UnitsList_unit__status_battery from "@/components/units/UnitsList_unit__status_battery.vue";
    //import UnitsList_unit_menu from "@/components/units/UnitsList_unit_menu.vue";
    import UnitsList_unit_menu from "@/components/units/UnitsList_unit_menu.intuit.vue";
    import UnitsList_unit_icons from "@/components/units/UnitsList_unit_icons.vue";
    import UnitsList_unit_sensors from "@/components/units/UnitsList_unit_sensors.vue";
    import {computed} from "vue";

    import UnitsList_unit from "@/components/units/UnitsList_unit.intuit.vue"
    import UnitsList_unit_xpower from "@/components/units/UnitsList_unit_xpower.intuit.vue"
    import UnitsList_unit_xlink from "@/components/units/UnitsList_unit_xlink.intuit.vue"
    import UnitsList_unit_xtag from "@/components/units/UnitsList_unit_xtag.intuit.vue"
    // let indicatorsByStatus = {
    //     default: ['ignition', 'moving', 'text'],
    //     video: ['ignition', 'moving', 'text', 'video'],
    //     reefer: ['ignition', 'moving', 'text', 'reefer'],
    // }

    export default {
        name: 'UnitsList_unit_xlink',
        inject: ['setRoute'],
        components: {
            UnitsList_unit_icons,
            UnitsList_unit_address,
            UnitsList_unit_timeago,
            UnitsList_unit__status,
            UnitsList_unit_sensors,
            UnitsList_unit__status_battery,
            UnitsList_unit_menu,
            UnitsList_unit,
            UnitsList_unit_xpower,
            UnitsList_unit_xlink,
            UnitsList_unit_xtag,
        },
        props: {
            'unitId': [String, Number],
            'groupId': [String, Number],
        },
        data() {
            return {
                modalsWindows: {},
                showMenu: true,
                showListParams: false,
            }
        },
        provide() {
            return {
                modalsWindows: computed(() => this.modalsWindows)
            }
        },
        computed: {
            ...mapGetters([
                "getTimeToday",
                "unitsByIds",
                // "groupsShowUnits",
                // "groupsShowUnitsByIds",

                "unitsLmsgsByIds",
                "getAppUserUnits",
                "units",
            ]),
            routeName() {
                return this.$route.name
            },
            routeFilter() {
                return this.$route.params
            },
            routeQueryFilter() {
                return this.$route.query
            },
            unit(){
                return this.unitsByIds[this.unitId]
            },
            accessRight(){
                return  this.unit?.access_right || 0
            },
            unitName(){
                return this.unit?.hw_id || ''
            },
            unitLmsg() {
                return this.unitsLmsgsByIds[this.unitId]
            },
            unitLmsgSensors() {
                return this.unitLmsg?.sensors || {}
            },
            indicators() {
                // let status = 'default'
                // if(this.isReefer) status = 'reefer'
                // else if(this.hasVideo) status = 'video'
                return []//indicatorsByStatus[status]
            },
            isActive(){
                return this.routeFilter.unitId == this.unitId
            },
            unit_outputs(){
                return this.unit?.outputs?.filter(o => o.name > '' && o.param > '') || []
            },
            showListParamsButtonIcon() {
                return `vector-` + (this.showListParams ? 'top' : 'down')
            },
            childrenUnits() {
                return this.units
                    .filter(u => u.parent__id === this.unitId)
                    .map(unit => {
                        let type = unit?.hw_type
                        type = this.$consts.unitsList.useTypeForTemplate.includes(type) ? '_'+type : ''
                        return {xid: this.groupId + unit.id, groupId: this.groupId, type, unitId: unit.id, parentId: this.unitId}
                    })
            }
        },
        watch: {
            isActive(newVl) {
                if (!newVl) {
                    this.showListParams = false
                }
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
            setUnitActive(){
                return false;
                //this.setRoute.unitId(this.$route, this.$router, (this.parentId || this.unitId))
            },
        },
        updated() {
            // console.log(`unit ${this.unitId} updated`);
        },
        mounted() {
        },
        beforeUnmount() {
        },
    }
</script>
