import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {
    state: {
        videoRecords_index: [],
        videoRecords: [],
    },
    actions: {
        fetchVideoRecords4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                let isToday = (args.date == getters.getDateToday)
                if (!getters.apiToken || !args.unitId || !args.date) {
                    return reject(false)
                }

                // if today come throw 00h - it doesn't work (index ???)
                // let record = getters.getVideoRecords.find(r => (r.unit_id == args.unitId && r.time?.date == args.date))
                // let record = getters.getVideoRecordsIndex.find(i => i.date == args.date &&  i.unit_id == args.unitId  && (!i.timeout || i.timeout > (Date.now() / 1000)))
                // if (record && args.date !== getters.getDateToday) {
                //     return resolve(true)
                // }

                dispatch('setLastCall', {name: 'fetchVideoRecords4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastVideoRecords', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.videoRecords.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeVideoRecords', response.data)
                            commit('setVideoRecordsIndex', {
                                ...args,
                                count: response.data.length,
                                timeout: isToday ? ((Date.now() / 1000) + consts.indexTimeout.videoRecords) : false
                            })
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchVideoRecords4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastVideoRecords', inprogress: false})
                        }
                    });
            })
        },
        fetchVideoRecords4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.unitId || !args.from || !args.to) {
                    return reject(false)
                }
                let record = getters.getVideoRecords.find(r => (r.unit_id == args.unitId && r.time?.date == args.date))
                if (record) {
                    return resolve(true)
                }

                dispatch('setLastCall', {name: 'fetchVideoRecords4Date', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastVideoRecords', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.videoRecords.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeVideoRecords', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchVideoRecords4Date', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastVideoRecords', inprogress: false})
                        }
                    });
            })
        },
        reloadVideoRecords({commit, dispatch}, args) {
            commit('clearVideoRecords')
            return dispatch('fetchVideoRecords4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        removeVideoRecordsIndex(state) {
            state.videoRecords_index =
            state.videoRecords_index.filter(i => !i.timeout || i.timeout > (Date.now() / 1000))
        },
        setVideoRecordsIndex(state, params) {
            let i = state.videoRecords_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.videoRecords_index.push(Object.freeze({...params}))
            } else {
                state.videoRecords_index[i] = Object.freeze({...state.videoRecords_index[i], ...params})
            }
        },
        storeVideoRecords(state, nRecords) {
            if (!state.videoRecords.length) {
                state.videoRecords = nRecords.map(r => Object.freeze(r))
                return true
            }

            nRecords.forEach(function (nRecord) {
                let i = state.videoRecords.findIndex(r => (r.xid == nRecord.xid))
                if (i < 0) {
                    state.videoRecords.push(Object.freeze(nRecord))
                } else {
                    // updateObjectByDiff(state.videoRecords[i], nRecord)
                    delete nRecord.xid
                    state.videoRecords[i] = Object.freeze({...state.videoRecords[i], ...nRecord})
                }
            })
        },
        clearVideoRecords(state) {
            state.videoRecords = [];
            state.videoRecords_index = [];
        },
    },
    getters: {
        getVideoRecordsIndex(state) {
            return state.videoRecords_index
        },
        getVideoRecords(state) {
            return state.videoRecords
        },
        getVideoRecordsByIds(state) {
            return state.videoRecords.reduce((videoRecordsByIds, event) => {
                videoRecordsByIds[event.id] = event
                return videoRecordsByIds
            }, {})
        },
    }
}
